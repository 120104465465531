import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioPhoto from "../components/portfolioPhoto"

import "../css/site.css"
import "animate.css"

class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      photos1: [
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1631485298/portfolio/EE218A1E-5BC4-4151-8721-CDC47D1A1603_bujddb.jpg",
          credit: "Kayleigh Maes",
          credit_href: "",
          alt: "",
        },
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1631485164/portfolio/567842BE-9506-4C3B-8B84-A34A4A182CD5_jbpf3s.jpg",
          credit: "Ashley Shaw",
          credit_href: "",
          alt: "",
        },

        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1631485373/portfolio/C8A8A59F-9E8C-4681-A1BF-63127074F3C2_gx6rre.jpg",
          credit: "Kayleigh Maes",
          credit_href: "",
          alt: "",
        },
      ],
      photos2: [
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1610229504/portfolio/136a5542_k680hv.jpg",
          credit: "Emily Brown",
          credit_href: "",
          alt: "",
        },
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1631484028/portfolio/IMG_9582_xhxjui.jpg",
          credit: "Emily Brown",
          credit_href: "",
          alt: "",
        },
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1631484050/portfolio/IMG_9192_scmajg.jpg",
          credit: "Josie V",
          credit_href: "",
          alt: "",
        },
      ],
      photos3: [
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1631484039/portfolio/IMG_0564_uf2kcn.jpg",
          credit: "Briana Nicole",
          credit_href: "",
          alt: "",
        },
        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1610229515/portfolio/IMG_1004_uqswuf.jpg",
          credit: "Annie Quick",
          credit_href: "",
          alt: "",
        },

        {
          src:
            "https://res.cloudinary.com/jackiesue/image/upload/w_600/v1610229513/portfolio/IMG_1969_jftv3g.jpg",
          credit: "Annie Quick",
          credit_href: "",
          alt: "",
        },
      ],
    }
  }

  componentDidMount() {}

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <div className="photos-container animated fadeIn">
          <div className="row">
            <div className="column">
              {this.state.photos1.map((photo, index) => (
                <PortfolioPhoto
                  key={index}
                  src={photo.src}
                  alt={photo.alt}
                  creditContent={() => (
                    <span>
                      Photo by{" "}
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          color: "#ffffff",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={photo.credit_href}
                      >
                        {photo.credit}
                      </a>
                    </span>
                  )}
                />
              ))}
            </div>
            <div className="column">
              {this.state.photos2.map((photo, index) => (
                <PortfolioPhoto
                  key={index}
                  src={photo.src}
                  alt={photo.alt}
                  creditContent={() => (
                    <span>
                      Photo by{" "}
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          color: "#ffffff",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={photo.credit_href}
                      >
                        {photo.credit}
                      </a>
                    </span>
                  )}
                />
              ))}
            </div>
            <div className="column">
              {this.state.photos3.map((photo, index) => (
                <PortfolioPhoto
                  key={index}
                  src={photo.src}
                  alt={photo.alt}
                  creditContent={() => {
                    let content = (
                      <span>
                        Photo by{" "}
                        <a
                          style={{
                            fontWeight: "bold",
                            textDecoration: "none",
                            color: "#ffffff",
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={photo.credit_href}
                        >
                          {photo.credit}
                        </a>
                      </span>
                    )

                    // custom credit for this photo since we link to two different sites
                    if (
                      photo.credit ===
                      "Lauren Shipman with Danielle Bridges as Key MUA"
                    ) {
                      content = (
                        <span>
                          Photo by{" "}
                          <a
                            style={{
                              fontWeight: "bold",
                              textDecoration: "none",
                              color: "#ffffff",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/lnshipphoto"
                          >
                            Lauren Shipman
                          </a>{" "}
                          with{" "}
                          <a
                            style={{
                              fontWeight: "bold",
                              textDecoration: "none",
                              color: "#ffffff",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.bbeautylife.com/"
                          >
                            Danielle Bridges
                          </a>{" "}
                          as Key MUA
                        </span>
                      )
                    }

                    return content
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
