import React from "react"

import portfolioPhotoStyles from "../css/portfolio-photo.module.css"

const portfolioPhoto = props => (
  <div className={portfolioPhotoStyles.container}>
    <img src={props.src} alt={props.alt} />
    <div className={portfolioPhotoStyles.overlay}>
      <div className={portfolioPhotoStyles.credit}>{props.creditContent()}</div>
    </div>
  </div>
)

export default portfolioPhoto
